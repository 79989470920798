import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { Day, weekDays } from '../../modules/my-day/helpers/calendar';
import { Knob } from '../ui/Knob';
import { GridContainer } from '../layout/elements';
import store from 'src/store/Store';
import { IndiSubscriptionTypes } from '../../libs'
import { openPaymentSubscription } from 'src/modules/payment/components/payment-subscription';


export const CALENDAR_IS_OPENED_CLASS = 'CALENDAR_IS_OPENED_CLASS';

interface CalendarProps {
	showCalendar: boolean
	sliderItems: Day[][][]
	calendarContainerRef: any
	sliderContainerRef: any
	sliderItemRef: any
	className?: string
	setSelected(date: dayjs.Dayjs): void
	toPreviousMoth(): void
	toNextMoth(): void
	onClose(): void
}

const minDistForCloseX = 15
const minDistForCloseY = 50

export const Calendar = observer((props: CalendarProps) => {
	const startX = useRef(0)
	const startY = useRef(0)
	const diffX = useRef(0)
	const diffY = useRef(0)
	const isStartedX = useRef(false)
	const isStartedY = useRef(false)

	const startEvent = isMobile ? 'touchstart' : 'mousedown';
	const moveEvent = isMobile ? 'touchmove' : 'mousemove';
	const endEvent = isMobile ? 'touchend' : 'mouseup';

  const blockChangeDate = store.sessionData?.indi?.isCardlessTrial

	const touchStart = (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		const clientX = ('touches' in e) ? e.touches[0].clientX : e.clientX;

		startX.current = clientX;
		diffX.current = 0;
		isStartedX.current = true;

		props.sliderContainerRef.current!.querySelector('div')!.style.transition = `none`;
		props.sliderContainerRef.current?.addEventListener(moveEvent, touchMove);
		props.sliderContainerRef.current?.addEventListener(endEvent, touchEnd);
		window.addEventListener('mouseup', touchEnd);
	}

	const touchMove = (e: TouchEvent | MouseEvent) => {
		if (isStartedX.current && !isStartedY.current) {
			const clientX = ('touches' in e) ? e.touches[0].clientX : e.clientX;
			diffX.current = clientX - startX.current
				props.sliderContainerRef.current!.querySelector('div')!.style.transform = `translateX(${diffX.current}px)`
		}
	}

  const touchEnd = () => {
    if (Math.abs(diffX.current) > minDistForCloseX) {
      if(diffX.current > 0) {
				props.toPreviousMoth()
      } else {
					props.toNextMoth()
      }
    }

		props.sliderContainerRef.current?.removeEventListener(startEvent, touchStart);
		props.sliderContainerRef.current?.removeEventListener(moveEvent, touchMove);
		props.sliderContainerRef.current?.removeEventListener(endEvent, touchEnd);
		window.removeEventListener('mouseup', touchEnd);

		setTimeout(() => {
			isStartedX.current = false
		}, 0)
	}

	const touchKnobStart = (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
		const clientY = ('touches' in e) ? e.touches[0].clientY : e.clientY;

		startY.current = clientY;
		diffY.current = 0;
		isStartedY.current = true;

		props.calendarContainerRef.current.style.transition = `none`;
		props.calendarContainerRef.current?.addEventListener(moveEvent, touchKnobMove);
		props.calendarContainerRef.current?.addEventListener(endEvent, touchKnobEnd);
		window.addEventListener('mouseup', touchKnobEnd);
	}

	const touchKnobMove = (e: TouchEvent | MouseEvent) => {
	  if (isStartedY.current && !isStartedX.current) {
	    const clientY = ('touches' in e) ? e.touches[0].clientY : e.clientY;
	    diffY.current = clientY - startY.current;
		props.calendarContainerRef.current.style.transform = `translateY(${diffY.current > 0 ? 0 : diffY.current}px)`
	  }
  }

  const touchKnobEnd = () => {
    isStartedY.current = false;
		props.calendarContainerRef.current.style.transition = `0.5s`;

		if (!isStartedX.current) {
			if (Math.abs(diffY.current) > minDistForCloseY) {
				props.calendarContainerRef.current.style.transform = `translateY(-100%)`
				props.onClose()
			} else {
				props.calendarContainerRef.current.style.transform = `translateY(0px)`;
			}
		}

		props.calendarContainerRef.current?.removeEventListener(startEvent, touchKnobStart);
    props.calendarContainerRef.current?.removeEventListener(moveEvent, touchKnobMove);
    props.calendarContainerRef.current?.removeEventListener(endEvent, touchKnobEnd);
    window.removeEventListener('mouseup', touchKnobEnd);
  }

	const onSelect = (day: Day) => {
    if (blockChangeDate && !dayjs().isSame(day.date, 'day')) {
      openPaymentSubscription()
    } else {
      props.setSelected(dayjs(day.date).startOf('day'))
    }
	}

  return (
		<Container
			ref={props.calendarContainerRef}
			showCalendar={props.showCalendar}
			isMobile={isMobile}
			className={`${props.className ?? ''} ${props.showCalendar ? CALENDAR_IS_OPENED_CLASS : ''}`}
		>
			<Table>
				<Header>
					<tr>
						{weekDays.map(day => <th key={day}>{day}</th>)}
					</tr>
				</Header>
			</Table>

			<SliderContainer ref={props.sliderContainerRef}
											 onTouchStart={touchStart}
											 onMouseDown={touchStart}
											 >
				<Slider>
					{
						props.sliderItems.map((month, index) => (
							<Table key={`calendar_month_${index}`}
												ref={props.sliderItemRef}
												id={`calendar_month_${index}`}>
								<tbody>
										{
											month.map((week, index) =>
						            <tr key={`calendar_week_${index}`} className="week">
						                {
															week.map((day, index) => (
						                    <td
																	key={`calendar_day_${index}`}
																	className={day.type}
						                    >
																	<div onClick={() => onSelect(day)}>
																		{day.date.date()}
																	</div>
																</td>
															))
														}
						            </tr>
						          )
										}
								</tbody>
							</Table>
						))
					}
				</Slider>
			</SliderContainer>

			<Knob onTouchStart={touchKnobStart}
					  onMouseDown={touchKnobStart}/>
	  </Container>
	)
})

const Container = styled.div<{showCalendar: boolean, isMobile: boolean}>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	background: ${p => p.isMobile ? 'var(--color-white)' : 'var(--desktop-bg)'};
	transform: translateY(-100%);
  transition: 0.5s;

	${p => p.showCalendar && css`
		box-shadow: 0px 0.75px 2px 0px rgba(137, 146, 172, 0.12), 0px 14px 14px 0px rgba(101, 106, 122, 0.16);
	`}
`

const Table = styled.table`
	width: 100%;
	padding-top: 0.5rem;
	color: var(--text-third);

	td {
		font-size: 0.875rem;
		font-weight: 500;
		font-family: Inter;
		text-align: center;
		padding: 0.5rem 0;
	}

	div {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 1.75rem;
		height: 1.75rem;
		margin: 0 auto;
	}

	.selected {
		div {
			color: var(--color-white);
			background: var(--color-black);
			border-radius: 50%;
		}
	}

	.today {
		color: var(--text-primary);
	}

	.disabled {
		color: var(--text-disabled);
	}
`

const Header = styled.thead`
	th {
		color: var(--text-third);
		font-family: Inter;
		font-size: 0.75rem;
		font-weight: 500;
	}
`

const SliderContainer = styled.div`
  width: 100%;
  overflow: hidden;
  transition: height 0.3s;
	color: var(--text-third);
	padding-bottom: 0.2rem;
`

const Slider = styled(GridContainer)`
  width: 300%;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  left: -100%;
`
