import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import styled, { css } from "styled-components";
import { Colors, Positions, Tip } from "../../components/Tip";
import { PopupContainer } from "../../components/ui/Popup/Popup";
import { gtmSend } from "../../helpers/url";
import { getMainPageLS, MainPageTips, setMainPageLS } from "../../store/localStorageHelpers";
import { popupsStore } from "../../store/Popups";
import { shutterBottom, ShutterPositions } from "../../store/Shutter";
import store from "../../store/Store";
import { windowsStore } from "../../store/Windows";
import { Blog } from "../blog/Blog";
import { BlogNew } from "../blog/components/BlogNew";
import { Menu } from "./components/Menu";


const tipContent = {
  text : [
    'В разделе Продукты вы можете приобрести дополнительные материалы для личностного роста и анализа личности',
    'В разделе Консультации вы сможете записаться к специалисту для более глубокого анализа ваших запросов и получения поддержки'
  ],
  buttonTitle : [
    'Далее',
    'Завершить'
  ]
}

interface ShutterProps {
  newCounterRef: React.RefObject<HTMLDivElement>
  rollDown: () => void
}

export const Shutter = observer((props: ShutterProps) => {
  const [showTip, setShowTip] = useState<number | null>(null)
  const banners = store.products.banners
  const containerRef = useRef<HTMLDivElement | null>(null)

  const scrolledRef = useRef(false)

  const userId = store.sessionData?.id;
  const shutterPosition = store.shutter.position

  const hideShutter = windowsStore.getWindows().find(window => window.isModal) || popupsStore.getPopups().length > 0

  const onCloseTip = useCallback(() => {
    setShowTip(null)

    const mainPageDataLS = getMainPageLS()

    const newData = {
      ...mainPageDataLS,
      onboarding: {
        ...mainPageDataLS.onboarding,
        showedTips: [
          ...mainPageDataLS.onboarding?.showedTips ?? [],
          MainPageTips.SHUTTER
        ],
      }
    }

    setMainPageLS(newData)
  }, [])

  useLayoutEffect(() => {
    store.shutter.setShutterRef(containerRef)
  }, [])

  useEffect(() => {
    const scrollHandler = () => {
      const scrollTop = containerRef.current?.scrollTop ?? 0

      if (scrollTop > 300 && !scrolledRef.current) {
        gtmSend({ 'event': `indi_hub_scroll`, 'user_id': userId ? userId : '' })
        scrolledRef.current = true
      }
    }

    containerRef.current?.addEventListener('scroll', scrollHandler)

    return () => {
      containerRef.current?.removeEventListener('scroll', scrollHandler)
    }
  }, [userId])

  useEffect(() => {
    const mainPageDataLS = getMainPageLS()

    if (mainPageDataLS?.onboarding?.showedTips?.includes(MainPageTips.SHUTTER)) return

    if (
      store.onboarding.detectOldUser() ||
      (shutterPosition === ShutterPositions.BOTTOM && showTip)
    ) {
      onCloseTip()
      return
    }

    if (
      shutterPosition === ShutterPositions.MIDDLE &&
      !showTip &&
      mainPageDataLS?.onboarding?.showedOnboarding
    ) {
      setTimeout(() => setShowTip(1), 2000)
    }
  }, [shutterPosition, onCloseTip, showTip])

  const containerStyles: React.CSSProperties = isMobile
    ? {
        'zIndex': 'var(--z-index-shutter)',
        'overflow': 'hidden',
        'width': '100%',
        'transform': `translateY(-${shutterBottom}px)`,
        'height': '100dvh',
        'position': 'fixed',
        'bottom': '-100%',
      }
    : {
        'overflow': 'initial',
      };

  const skipTip = useCallback(() => {
    onCloseTip()
    gtmSend({'event': `indi_onboarding_hub_products_skip`, 'user_id': userId ? userId : ''})
  }, [onCloseTip, userId])

  useEffect(() => {
    return () => {
      const mainPageDataLS = getMainPageLS()

      if (
        mainPageDataLS?.onboarding?.showedOnboarding &&
        !mainPageDataLS?.onboarding?.showedTips?.includes(MainPageTips.SHUTTER)
      ) {
        onCloseTip()
      }
    }
  }, [onCloseTip])

  useEffect(() => {
    store.products.getArticlesGroups()
  }, []);

  const tipButton = useMemo(() => {
    const onClickHandler = () => {
      if (!showTip) return

       if (showTip === 1) {
        setShowTip(2)
        gtmSend({'event': `indi_onboarding_hub_products`, 'user_id': userId ? userId : ''})
       } else {
         onCloseTip()
         gtmSend({'event': `indi_onboarding_hub_consult`, 'user_id': userId ? userId : ''})
       }
    }

    const index = (showTip ?? 1) - 1

    return {
      title: tipContent.buttonTitle[index],
      onClick: onClickHandler,
      position: Positions.RIGHT
    }
  }, [showTip, onCloseTip, userId])

  const indicatorButton = useMemo(() => {
    return {
      length: 2,
      current: (showTip ?? 1),
    }
  }, [showTip])

  const renderBanners = () => (
    <ContentContainer>
      <Content>
        {banners.map((banner) => (
          <BannerContainer key={banner.id} onClick={() => window.open(banner.link, "_blank")}>
            <img src={banner.imageUrl} alt={`Баннер-${banner.id}`} />
          </BannerContainer>
        ))}
      </Content>
    </ContentContainer>
  );

  return (
    <StyledPopupContainer
      style={containerStyles}
      popupRef={containerRef}
      className={'ob-0'}
      background={isMobile ? 'var(--color-white)' : ''}
      showNotification
      hideShutter={!!hideShutter}
    >
      <BlogNew newCounterRef={props.newCounterRef}/>
      <Menu rollDown={props.rollDown}/>
      {renderBanners()}
      <Blog rollDown={props.rollDown}/>

      {
        showTip && (
          <TipWrapper showTip={showTip}>
            <StyledTip
              color={Colors.DARK}
              text={tipContent.text[showTip - 1]}
              onClose={skipTip}
              trianglePosition={showTip === 1 ? Positions.TOP_RIGHT : Positions.TOP_LEFT}
              button={tipButton}
              indicator={indicatorButton}
              stage={showTip}
            />
          </TipWrapper>
        )
      }
    </StyledPopupContainer>
  )
})

const StyledPopupContainer = styled(PopupContainer)<{hideShutter: boolean}>`
  display: ${p => p.hideShutter ? 'none' : 'block'};
`

const TipWrapper = styled.div<{showTip: number}>`
  position: absolute;
  top: ${p => p.showTip === 1 ? '95px' : '215px'};
  padding: 1rem;
  width: 90%;

  ${p => p.showTip === 1 && css`
    right: 0;
  `}

  ${p => p.showTip === 2 && css`
    left: 0;
  `}
`

const StyledTip = styled(Tip)<{stage: number}>`
  .triangle {
    ${p => p.stage === 1 && css`
      right: 2.75rem;
    `}

    ${p => p.stage === 2 && css`
      left: 2.75rem;
    `}
  }
`

const BannerContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  cursor: pointer;
  border-radius: 20px;
  padding: 0 1rem 1rem;
  scroll-snap-align: start;
  img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 20px;
  }
`;

const ContentContainer = styled.div`
  overflow-x: scroll;
  scroll-snap-type: x mandatory;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    margin: 0 1rem;
  }
`;

const Content = styled.div`
  scroll-snap-align: start;
  display: flex;
  transition: scroll-left 0.5s ease-in-out;
  align-items: center;
`;
