import { memo } from 'react';
import styled, { css } from 'styled-components';
import { LockIcon } from '../../../assets/icons/system/system-icons';
import { FlexContainer } from '../../../components/layout/elements';
import { Article } from '../helpers/blog';

interface BlogArticleProps {
  article: Article;
  onClick(): void;
  isNew: boolean;
  lock: boolean;
  isToday?: boolean;
}

export const BlogArticle = memo((props: BlogArticleProps) => {
  const { article, onClick, isNew, lock, isToday } = props;
  return (
    <Container
      key={article.id}
      background={article.imageUrl}
      onClick={onClick}
      theme={article.theme}
    >
      <Title dangerouslySetInnerHTML={{ __html: article.title }}></Title>
      <Info>
        {isToday && <Today>Сегодня</Today>}
        {isNew && <New>Новое</New>}
        {article.time && <Time>{article.time}</Time>}
      </Info>

      <Footer>
        {lock && article.subscription && (
          <Lock>
            <LockIcon /> Подписка
          </Lock>
        )}
      </Footer>
    </Container>
  );
});

const Container = styled.div<{ background: string; theme: 'white' | 'dark' }>`
  box-shadow: 0px 4px 24px rgba(22, 22, 33, 0.06);
  border-radius: 20px;
  padding: 1rem;
  margin-right: 1rem;
  width: 16rem;
  height: 20rem;
  color: var(--text-primary-white);
  cursor: pointer;
  position: relative;

  ${(p) =>
    p.background &&
    css`
      background: url(${p.background}) 0 0 / cover no-repeat;
    `}

  ${(p) =>
    p.theme === 'dark' &&
    css`
      color: var(--text-secondary);
    `}
`;

const Info = styled(FlexContainer)`
  justify-content: flex-start;
`;

const New = styled.div`
  color: var(--color-white);
  padding: 0.1rem 0.5rem;
  margin-top: 1rem;
  border-radius: 32px;
  background: var(--color-blue);
`;

const Today = styled.div`
  color: var(--color-white);
  padding: 0.1rem 0.5rem;
  margin-top: 1rem;
  margin-right: 8px;
  border-radius: 32px;
  background: #000000e5;
`;

const Footer = styled(FlexContainer)`
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
`;

const Lock = styled(FlexContainer)`
  align-items: center;

  color: var(--color-white);
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  background: var(--color-blue);
`;

const Title = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
`;

const Time = styled.span``;
