export function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

export function detectIphone12ProMax() {
  const isIOSDevice = isIOS()
  const screenWidth = window.screen.width
  const screenHeight = window.screen.height
  const pixelRatio = window.devicePixelRatio

  if (isIOSDevice && pixelRatio === 3) {
    if ((screenWidth === 428 && screenHeight === 926) || (screenWidth === 926 && screenHeight === 428)) {
      return true
    }
  }

  return false
}

export function detectSamsungC20() {
  const userAgent = navigator.userAgent.toLowerCase()

  if (userAgent.includes("sm-c20")) {
      return true
  } else {
      return false
  }
}
