import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { InstallPWAPopup } from '../install-pwa/InstallPWAPopup';
import { DESKTOP_GAP, DESKTOP_WIDTH } from '../../helpers/ui';
import { gtmSend, searchParams } from '../../helpers/url';
import { useAppInstall } from '../../hooks/app-install-hooks';
import { useMainPopupScroll } from '../../hooks/popup-hooks';
import store from '../../store/Store';
import { getAppLS, getMainPageLS, setMainPageLS } from '../../store/localStorageHelpers';
import { SubscribeModes } from '../payment/helpers/tariffs';
import { openPotential } from '../potential/Potential';
import { Shutter } from '../shutter/Shutter';
import { MainPageActiveSubscriptionScreen } from './components/active-subscription/main-page-active-subscription-screen';
import { MainPageBlocks } from './components/main-page-blocks';
import { MainPageHeader } from './components/main-page-header';
import { MainPageInfoBanner } from './components/main-page-info-banner';
import { TUserStateKeys, states } from './helpers/states';
import Overlay from '../../components/Overlay';
import { history } from 'src/MainRouter';
import { MainPageOnboarding } from './Onboarding/main-page-onboarding';
import { OnboardingDataFilling } from '../data-filling-form/data-filling-form';
import { ShutterOnboarding } from '../shutter/Onboarding/shutter-onboarding';
import { Indi, Logging } from 'src/libs';
import { windowsStore } from 'src/store/Windows';


export const MainPage = observer(() => {
  const [selectedDay, setSelectedDay] = useState(dayjs().startOf('day'))
  const [scrolled, setScrolled] = useState(false)
  const [showInfoBanner, setShowInfoBanner] = useState(false)
  const [showOnboarding, setShowOnboarding] = useState(false)
  const [showShutterOnboarding, setShowShutterOnboarding] = useState(false)

  const overlayRef = useRef<HTMLDivElement>(null);
  const mainPageRef = useRef<HTMLDivElement>(null);
  const newCounterRef = useRef<HTMLDivElement>(null);

  const status = store.events.status;
  const userId = store.sessionData?.id || '';
  const info = store.sessionData?.indi;
  const shutterRef = store.shutter.shutterRef
  const {startPlan} = store.getIndiDates()
  const profile = store.profile
  const fullPersonalData = profile.firstName && profile.birth.dateTime && profile.birth.place?.name
  const windows = windowsStore.getWindows()

  const { rollDown } = useMainPopupScroll(
    shutterRef!,
    overlayRef,
    mainPageRef,
    newCounterRef,
    userId.toString()
  );

  useEffect(() => {
    store.logger.startLoggingSession(Logging.Screens.MAIN)

    return () => {
      store.logger.createLoggingSession(Logging.Screens.MAIN)
    }
  }, [])

  const checkGtm = useCallback(() => {
    const sp = searchParams()
    const gtmParam = sp.get('gtm')

    if (gtmParam) {
      gtmSend({ 'event': gtmParam, 'user_id': userId ? userId : '' })
      sp.delete('gtm')
      history.push({ pathname: window.location.pathname, search: sp.toString() })
    }
  }, [userId])

  const checkNotifications = useCallback(async () => {
    if (!userId) return

    const showedModal = await store.webPush.getModal(userId)

    if (
      store.pwa.isPWA() &&
      (
        store.webPush.detectOldUser() || dayjs().diff(dayjs(startPlan), 'day', true) > 1
      )  &&
      !showedModal
    ) {
      store.modal.showHandler.notificationsModal = true
    }
  }, [userId, startPlan])

  useEffect(() => {
    checkGtm()
    checkNotifications()
  }, [checkGtm, checkNotifications])

  const checkOnboarding = useCallback(() => {
    if (status === 'active') {
      const mainPageLS = getMainPageLS()

      if (mainPageLS.onboarding?.showedOnboarding) return

      if (store.onboarding.detectOldUser()) {
        const newData = {
          ...mainPageLS,
          onboarding: {
            ...mainPageLS.onboarding,
            showedOnboarding: true,
          }
        }

        setMainPageLS(newData)
      } else {
        setShowOnboarding(true)
        if (mainPageRef.current && !isMobile) mainPageRef.current.style.height = 'auto'
      }
    }
  }, [status])

  const hideOnboarding = useCallback(() => {
    setShowOnboarding(false)
    if (mainPageRef.current && !isMobile) mainPageRef.current.style.height = '100vh'
  }, [setShowOnboarding])

  const hideShutterOnboarding = useCallback(() => {
    setShowShutterOnboarding(false)

    const mainPageLS = getMainPageLS()

    const newData = {
      ...mainPageLS,
      onboarding: {
        ...mainPageLS.onboarding,
        showedShutterOnboarding: true,
      }
    }

    setMainPageLS(newData)
  }, [setShowShutterOnboarding])

  useEffect(() => {
    return () => {
      !showOnboarding && showShutterOnboarding && hideShutterOnboarding()
    }
  }, [showOnboarding, showShutterOnboarding, hideShutterOnboarding])

  const checkShutterOnboarding = useCallback(() => {
    if (status === 'active') {
      const mainPageLS = getMainPageLS()
      const appLS = getAppLS()

      if (mainPageLS.onboarding?.showedShutterOnboarding) return

      if (store.onboarding.detectOldUser()) {
        hideShutterOnboarding()
        return
      }

      if (appLS.sessionCount > 2 && !mainPageLS?.usedShutter) {
        setTimeout(() => {
          setShowShutterOnboarding(true)
        }, 2000)
      }
    }
  }, [status, setShowShutterOnboarding, hideShutterOnboarding])

  useEffect(() => {
    checkOnboarding()
    checkShutterOnboarding()
  }, [status, checkOnboarding, checkShutterOnboarding])


  useEffect(() => {
    const showInfoBanner = () => {
      const mainPageLS = getMainPageLS()

      if (newCounterRef.current?.style.display === 'none' && !mainPageLS.infoBanner?.closed) {
        setShowInfoBanner(true)
      }
    }

    showInfoBanner()

    shutterRef && shutterRef.current?.addEventListener('touchend', showInfoBanner)

    return () => {
      shutterRef && shutterRef.current?.removeEventListener('touchend', showInfoBanner)
    }
  }, [])

  const { isVisible, installApp } = useAppInstall();

  let stateName: TUserStateKeys | null = store.events.status || 'unlogin';

  if (!stateName) return null;

  const state = states[stateName];
  const Container = isMobile ? Mobile : Desktop;

  const Icon = store.events.getIcon(dayjs(selectedDay))

  const onCloseInfoBanner = useCallback(() => {
    const mainPageLS = getMainPageLS()

    const newMainPageLS = {
      ...mainPageLS,
      infoBanner: {
        ...mainPageLS.infoBanner,
        closed: true
      }
    }

    setMainPageLS(newMainPageLS)
    setShowInfoBanner(false)
  }, [])

  const onClickInfoBanner = useCallback(() => {
    openPotential(true, selectedDay)
    gtmSend({ 'event': 'indi_potential_info_banner', 'user_id': userId ? userId : '' })
    onCloseInfoBanner()
  }, [onCloseInfoBanner, selectedDay, userId])

  const getShowInfoBanner = useCallback(() => {
    const appLS = getAppLS()
    const isOldUser = dayjs().diff(dayjs(store.sessionData?.indi?.startDate), 'day') > 1

    if (
      showInfoBanner &&
      Icon &&
      store.events.getPercent(selectedDay) <= 50
    ) {
      if (isOldUser) {
        return true
      } else {
        return appLS?.sessionCount > 1 || dayjs().diff(dayjs(appLS.lastSession), 'day') > 1
      }
    } else {
      return false
    }
  }, [Icon, selectedDay, showInfoBanner])

  if (status === 'active' && profile.id !== -1 && !fullPersonalData) {
    return <OnboardingDataFilling/>
  }

  return (
    <>
      <Container ref={mainPageRef}>
        <MainPageHeader
          state={state}
          selected={selectedDay}
          scrolled={scrolled}
          setSelected={setSelectedDay}
        />

        <Content
          isMobile={isMobile}
          isActive={status === 'active'}
        >

          {
            status === 'active'
              ? <MainPageActiveSubscriptionScreen
                  selectedDay={selectedDay}
                  setSelectedDay={setSelectedDay}
                  scrolled={scrolled}
                  setScrolled={setScrolled}
                  newCounterRef={newCounterRef}
                />
              : <MainPageBlocks stateName={stateName} />
          }
        </Content>

        {
          getShowInfoBanner() && (
            <StyledMainPageInfoBanner
              icon={Icon && <Icon />}
              text='Узнайте как формируется ваш потенциал дня'
              onClick={onClickInfoBanner}
              onClose={onCloseInfoBanner}
            />
          )
        }

        <Shutter newCounterRef={newCounterRef} rollDown={rollDown} />
        <Overlay
          className={'main-page_overlay'}
          overlayRef={overlayRef}
        />

        {
          showOnboarding && (
            <MainPageOnboarding
              hideOnboarding={hideOnboarding}
              mainPageRef={mainPageRef}
            />
          )
        }
        {
          !showOnboarding && showShutterOnboarding && windows.length === 0 && isMobile &&
          <ShutterOnboarding hideOnboarding={hideShutterOnboarding}/>
        }
      </Container>

      <div className='no-print'>
        {isVisible && <InstallPWAPopup onClick={installApp} />}
      </div>
    </>
  )
});

const Mobile = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  height: 100%;
`
const Desktop = styled.div`
  width: ${DESKTOP_WIDTH}px;
  height: 100vh;
  overflow: auto;
  position: absolute;
  left: 50%;
  transform: translateX(calc(-100% - ${DESKTOP_GAP / 2}px));
`

const Content = styled.div<{ isMobile: boolean, isActive?: boolean }>`
  ${p => p.isMobile && css`
    height: 100%;
    overflow-y: scroll;
  `};

  @media (max-height: 700px) {
    ${p => !p.isActive && css`
      padding-bottom: 8rem;
    `}
  }
`

const StyledMainPageInfoBanner = styled(MainPageInfoBanner)`
  top: 70vh;
  z-index: 5;

  @media (max-height: 680px) {
    top: 65vh;
  }
`
