import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { MainPadding, WindowContainer } from '../../components/layout/elements';
import { ROUTES } from '../../helpers/routes';
import { useBackWindow } from '../../hooks/router-hooks';
import store from '../../store/Store';
import { MainPageBlocksSubscriptionBanner } from '../main-page/components/main-page-blocks-subscription-banner';
import { TUserStateKeys } from '../main-page/helpers/states';
import PrognosesDaily from './prognoses-daily';
import PrognosesMonthly from './prognoses-monthly';
import { WindowHeader } from '../windows/components/WindowHeader';
import { useMoveShutter } from '../../hooks/popup-hooks';
import { windowsStore } from '../../store/Windows';
import ButtonSwitcher, { IOption } from '../../components/ui/ButtonSwitcher';
import { isTester } from 'src/helpers/autotests';
// import UsersPullBanner, { LS_KEY_usersPullBanner_clicked } from '../../components/UsersPullBanner';

export const WINDOW_ID_PROGNOSES: string = 'WINDOW_ID_PROGNOSES';

export enum HoroscopeTypes {
  DAY = 'day',
  MONTH = 'month',
}

export const HoroscopeTypeOptions: IOption[] = [
  {
    value: HoroscopeTypes.DAY,
    label: 'День'
  },
  {
    value: HoroscopeTypes.MONTH,
    label: 'Месяц'
  }
];

export const openPrognoses = (horoscopeType?: HoroscopeTypes) => windowsStore.open(
  WINDOW_ID_PROGNOSES,
  <Prognoses horoscopeType={horoscopeType}/>,
  undefined,
  false,
  true,
)

interface PrognosesProps {
  horoscopeType?: HoroscopeTypes
}

export const Prognoses = observer((props: PrognosesProps) => {
  useBackWindow(ROUTES.PROGNOSES, WINDOW_ID_PROGNOSES);

  const [horoscopeType, setHoroscopeType] = useState<HoroscopeTypes>(props.horoscopeType ?? HoroscopeTypes.DAY);

  const containerRef = useRef<HTMLDivElement | null>(null)

  let stateName: TUserStateKeys | null = store.events.status;
  const isAuthFinished = store.isAuthFinished;
  const showBanner = isAuthFinished && stateName !== 'active';
  const shutterRef = store.shutter.shutterRef
  const email = store.profile.email || ''

  useMoveShutter(containerRef, shutterRef)

  const clickMonthHandler = (period: HoroscopeTypes) => {
    setHoroscopeType(period);
    period === HoroscopeTypes.MONTH && window.dataLayer.push({ event: 'indi_general_month_prognosis', user_id: '123abc' });
  }

  return (
    <WindowContainer ref={containerRef}>
      <WindowHeader title={horoscopeType === HoroscopeTypes.DAY ? 'Общий прогноз' : 'Обзор месяца'}/>
      <Container>
        {
          (stateName !== 'active' || isTester(email)) && (
            <StyledButtonSwitcher
              options={HoroscopeTypeOptions}
              value={horoscopeType}
              onChange={clickMonthHandler}
            />
          )
        }

        {showBanner && <>
          <MainPageBlocksSubscriptionBanner page={'common'}/>
          <br />
        </>}

        { horoscopeType === 'day'
            ? <PrognosesDaily />
            : <PrognosesMonthly />
        }

      </Container>
    </WindowContainer>
  )
})

const Container = styled(MainPadding)`
  padding-bottom: 8rem;
`

const StyledButtonSwitcher = styled(ButtonSwitcher)`
  margin-bottom: 1rem;
`
