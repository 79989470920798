import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { DealAcceptance } from 'src/components/DealAcceptance';
import { LogoChronosPlus } from 'src/components/LogoChronosPlus';
import { CheckboxContainer } from 'src/components/ui/CheckboxContainer';
import styled from 'styled-components';
import { ChevronRightIcon } from '../../assets/icons/system/system-icons';
import background from '../../assets/images/backgrounds/bg_component.jpg';
import { defaultDateTime } from '../../components/PersonInfo';
import { Banner } from '../../components/banners/Banner/Banner';
import { PrognosesForm } from '../../components/forms/PrognosesForm';
import { MainPadding, WindowContainer } from '../../components/layout/elements';
import { Button } from '../../components/ui/Button/Button';
import api, { s3urls } from '../../helpers/api';
import { isConsentInvalid, isDateInvalid, isEmailInvalid, isNameInvalid, isPlaceInvalid, isTimeInvalid } from '../../helpers/fields';
import { ROUTES } from '../../helpers/routes';
import { gtmSend, searchParams } from '../../helpers/url';
import { useBackWindow } from '../../hooks/router-hooks';
import { Gender, IPlace } from '../../libs';
import store from '../../store/Store';
import { windowsStore } from '../../store/Windows';
import { openConsultationSignUp } from '../consultation-sign-up/ConsultationSignUp';
import { CopyRight, defaultPlace } from '../registration/Registration';
import { IconStyles, WindowHeader } from '../windows/components/WindowHeader';


export const WINDOW_ID_EDIT: string = 'WINDOW_ID_EDIT';

export interface GenderItem {
  value: Gender
  title: string
}

const genderItems: GenderItem[] = [
  {
    value: 'female',
    title: 'Женский',
  },
  {
    value: 'male',
    title: 'Мужской',
  },
]

interface EditProps {
  beforeAuth?: boolean;
  onProfileUpdate?: () => void;
  beforePayment?: boolean;
  showToaster?: () => void;
  direct?: boolean;
  skipUpdatingProfile?: boolean
}

export const openEditWindow = (props?: EditProps) => {
  windowsStore.open(
    WINDOW_ID_EDIT,
    <Edit
      beforeAuth={props?.beforeAuth}
      beforePayment={props?.beforePayment}
      onProfileUpdate={props?.onProfileUpdate}
      showToaster={props?.showToaster}
      direct={props?.direct}
      skipUpdatingProfile={props?.skipUpdatingProfile}
    />,
    'fade'
  )
}

export const Edit = observer((props: EditProps) => {
  useBackWindow(ROUTES.EDIT, WINDOW_ID_EDIT);

  const { firstName, birth: { dateTime, place: initialPlace }, originalDate, email, gender } = store.profile;
  //@ts-ignore
  const initialDate = dayjs.utc(dateTime || defaultDateTime)?.toISOString();
  const initialTime = dayjs.utc(dateTime || defaultDateTime)?.toISOString();

  const [name, setName] = useState(firstName ?? '');
  const [nameError, setNameError] = useState('');

  const [date, setDate] = useState(initialDate ?? '');
  const [dateError, setDateError] = useState('');

  const [time, setTime] = useState(initialTime ?? '');
  const [timeError, setTimeError] = useState('');

  const [place, setPlace] = useState<IPlace>(initialPlace || defaultPlace);
  const [placeError, setPlaceError] = useState('');

  const [currentEmail, setCurrentEmail] = useState(email ?? '');
  const [emailError, setEmailError] = useState('');

  const getGender = () => genderItems.find(item => item.value === gender) ?? genderItems[0]

  const [currentGender, setCurrentGender] = useState(getGender());

  const [disableButton, setDisableButton] = useState(false);

  const [consentFirst, setConsentFirst] = useState(true);
  const [consentSecond, setConsentSecond] = useState(true);
  const [consentError, setConsentError] = useState('');

  const rectificationDelay = store.rectification.rectificationDelay
  const rectificationSent = store.rectification.rectificationSent
  const userId = store.sessionData?.id || 'unauthorized';
  const timeNotion = props.beforeAuth
    ? 'Чем точнее время рождения, тем точнее прогноз. Если не помните, оставьте 12:00. Потом можно изменить в настройках.'
    : 'При необходимости вы сможете подкорректировать время рождения в настройках профиля'

  useEffect(() => {
    const sp = searchParams()
    const event = !props.direct
      ? 'indi_prognosis_personal-info_visit'
      : 'indi_prognos_pers-info_visit-light'

    gtmSend({
      'event': event,
      'userId': userId,
      'utm_source': sp.get('utm_source'),
    })
  }, [])

  useEffect(() => {
    // if (props.beforeAuth) return;
    checkDate(date, originalDate);
  }, [date, originalDate])

  const checkDate = (date: string, originalDate?: string) => {
    const dateError: string = isDateInvalid(date, originalDate);
    if (dateError) {
      setDateError(dateError);
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }

  const changeDateFormat = (value: string) => {
    return dayjs(value).format('YYYY-MM-DD')
  }

  const minDate = originalDate && changeDateFormat(dayjs(originalDate, 'DD.MM.YYYY').subtract(1, 'day').toString())
  const maxDate = originalDate && changeDateFormat(dayjs(originalDate, 'DD.MM.YYYY').add(1, 'day').toString())

  const onClose = () => windowsStore.close(WINDOW_ID_EDIT);

  const submit = async () => {
    try {
      if (props.direct) {
        gtmSend({'event': 'indi_profile_my_recti_data_done-light', 'user_id': userId})
      }

      if (!props.beforeAuth && !props.beforePayment ) {
        gtmSend({'event': 'indi_profile_my_recti_data_done', 'user_id': userId})
      }

      if (checkInvalidData()) return

      const _profile = {
        ...store.profile,
        firstName: name,
        birth: {
          //FIXME: fix types
          dateTime: {
            date: dayjs.utc(date).format('DD.MM.YYYY'),
            time: dayjs.utc(time).format('HH:mm:ss')
          } as any,
          place
        },
        email: currentEmail,
        gender: currentGender.value,
      }

      if (props.skipUpdatingProfile) {
        await store.updateProfile(store.convertProfile(_profile));
        props.onProfileUpdate?.();
        return
      }

      setDisableButton(true);

      await api.updateProfile({
        firstName: name,
        birth: {
          //FIXME: fix types
          //@ts-ignore
          dateTime: {
            date: dayjs.utc(date).format('DD.MM.YYYY'),
            time: dayjs.utc(time).format('HH:mm:ss')
          },
          place
        },
        email: currentEmail,
      } as any) // FIXME: any

      await store.getProfile();
      props.onProfileUpdate?.();
      onClose();
    } catch (e) {
      console.error(`Submit error: ${e}`)
    }
  }

  const onChangeNameHandler = (value: string) => {
    setName(value);
    setNameError('');
  }

  const onChangeDateHandler = (value: string) => {
    setDate(value);
    setDateError('');
  }

  const onChangeTimeHandler = (time: string) => {
    setTime(time)
    setTimeError('')
  }

  const onChangePalaceHandler = (value: any) => {
    if (typeof value === 'object') {
      setPlace({ name: value.name, lat: value.lat, lon: value.lon })
      setPlaceError('')
    } else {
      setPlace({ name: value, lat: 0, lon: 0 });
    }
  }

  const onChangeEmailHandler = (value: string) => {
    setCurrentEmail(value.trim())
    setEmailError('')
  }

  const onChangeConsentFirstHandler = (value: boolean) => {
    setConsentFirst(value)
    setConsentError('')
  }

  const onChangeConsentSecondHandler = (value: boolean) => {
    setConsentSecond(value)
    setConsentError('')
  }

  const checkInvalidData = () => {
    if (dateError || timeError) return true;

    const { nameErr, dateErr, placeErr, timeErr, emailErr, consentErr } = {
      nameErr: isNameInvalid(name),
      dateErr: isDateInvalid(date!),
      timeErr: isTimeInvalid(time!),
      placeErr: isPlaceInvalid(place),
      emailErr: isEmailInvalid(currentEmail),
      consentErr: props.beforeAuth ? isConsentInvalid(consentFirst) || isConsentInvalid(consentSecond) : '',
    }

    if (nameErr) setNameError(nameErr);
    if (dateErr) setDateError(dateErr);
    if (timeErr) setTimeError(timeErr);
    if (placeErr) setPlaceError(placeErr);
    if (emailErr) setEmailError(emailErr);
    if (consentErr) setConsentError(consentErr);

    return nameErr || dateErr || timeErr || placeErr || emailErr || consentErr
  }
  const orderRectificationSubmit = async () => {
    await store.userProfile.getProfile();
    const phoneNumber = store.userProfile.profile?.phoneNumber;
    if (!phoneNumber) {
      openConsultationSignUp(orderRectification, 'Запись на ректификацию');
    } else {
     orderRectification();
    }
  };

  const orderRectification = () => {
    store.rectification.orderRectification()
    gtmSend({'event': 'indi_profile_rectification_my_recti_data', 'user_id': userId})
  }

  const mailingAgreement = (
    <StyledCopyRight>
      Я принимаю&nbsp;
      <a href={s3urls.mailingAgreementRU} target="_blank" rel="noreferrer">соглашение на рассылку</a>
    </StyledCopyRight>
  )

  return <StyledWindowContainer background={background}>
    <StyledWindowHeader
      title={props.beforeAuth ? '' : 'Мои данные'}
      onBack={props.beforeAuth ? undefined : onClose}
      onClose={props.beforeAuth ? onClose : undefined}
      iconStyle={IconStyles.WITH_BG}
    />

    {
      props.beforeAuth && (
        <Header>
          <LogoChronosPlus/>
          <div className={'title'}>Чтобы построить прогноз, Chronos использует ваш день рождения</div>
        </Header>
      )
    }

    <Content>
      <PrognosesForm
        name={name}
        nameOnChange={onChangeNameHandler}
        nameError={nameError}
        namePlaceholder={'Как вас зовут?'}
        date={date!}
        dateOnChange={onChangeDateHandler}
        dateOnInvalid={() => setDateError('Введите корректную дату')}
        dateError={dateError}
        time={time!}
        timeOnChange={onChangeTimeHandler}
        timeOnInvalid={() => setTimeError('Введите корректное время')}
        timeError={timeError}
        timeNotion={timeNotion}
        place={place}
        placeOnChange={onChangePalaceHandler}
        placeError={placeError}
        placePlaceholder={'Где вы родились?'}
        minDate={minDate}
        maxDate={maxDate}
        originalDate={originalDate}
        sendAnalitics={props.beforeAuth}
        direct={props.direct}
        email={currentEmail}
        emailOnChange={onChangeEmailHandler}
        emailError={emailError}
        beforeAuth={props.beforeAuth}
        gender={currentGender}
        genderOnChange={setCurrentGender}
        genderItems={genderItems}
      />

      {
        props.beforeAuth && (
          <>
            <CheckboxContainer
              checked={consentFirst}
              onChange={onChangeConsentFirstHandler}
              text={<DealAcceptance/>}
              color={'var(--color-cornflower-blue)'}
            />

            <CheckboxContainer
              checked={consentSecond}
              onChange={onChangeConsentSecondHandler}
              text={mailingAgreement}
              error={consentError}
              color={'var(--color-cornflower-blue)'}
              iconError={false}
            />
          </>
        )
      }

      {
        !rectificationDelay && !props.beforeAuth &&
          <StyledBanner
            title='Узнайте точное время рождения'
            text='Свяжемся с вами, чтобы рассказать об услуге ректификации (уточнение времени рождения)'
            buttonContent={
              <ButtonContent>
                {rectificationSent ? 'Заявка отправлена' : 'Отправить заявку'}
                {!rectificationSent && <ChevronRightIcon />}
              </ButtonContent>}
            onClick={orderRectificationSubmit}
            rectificationSent={rectificationSent}
          />
      }

    </Content>

    <Footer>
      <Button
        onClick={submit}
        disabled={disableButton}
        color='gradient_purpure'
      >
        {props.beforeAuth ? 'Продолжить' : 'Сохранить'}
      </Button>
    </Footer>
  </StyledWindowContainer>
})

const StyledWindowContainer = styled(WindowContainer)<{background: string}>`
  background: ${p => `url(${p.background}) 0 0 / cover no-repeat`};
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-weight: 500;
    color: var(--text-secondary);
    text-align: center;
    width: 80%;
    margin: 1.125rem 0 1.5rem 0;
  }
`

const Content = styled(MainPadding)`
  flex: 1;
`

const Footer = styled(MainPadding)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: min-content;
`

const StyledBanner = styled(Banner)<{ rectificationSent?: boolean }>`
  margin-top: 1.5rem;

  .title {
    font-size: 1.125rem;
    color: var(--text-primary);
    width: 100%;
  }

  .text {
    color: var(--text-primary);
  }

  .banner_button {
    margin-top: 1.25rem;
    width: fit-content;
    background-color: ${({ rectificationSent }) => (rectificationSent ? '#0000000A' : 'var(--text-primary)')};
    color: ${({ rectificationSent }) => (rectificationSent ? 'black' : 'inherit')};
    transition: background-color 0.3s, color 0.3s;
  }
`

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.875rem;
  padding: 0 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--color-white);
  }
`

const StyledWindowHeader = styled(WindowHeader)`
  justify-content: space-between;
`

const StyledCopyRight = styled(CopyRight)`
  padding: 0;
  text-align: left;

  a {
    text-decoration: underline;
  }
`
