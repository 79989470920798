import React from 'react';
import styled from 'styled-components';
import { Article } from '../helpers/blog';
import { windowsStore } from 'src/store/Windows';
import { FlexContainer, WindowContainer } from 'src/components/layout/elements';
import { WindowHeader } from 'src/modules/windows/components/WindowHeader';


export const WINDOW_ID_ARTICLE_TEXT: string = 'WINDOW_ID_ARTICLE_TEXT';

interface BlogArticleTextProps {
  article: Article;
  title: string;
}

export const BlogArticleText: React.FC<
  BlogArticleTextProps
> = ({ article, title }) => {

  const descriptionNotNull = article.description || '';

  const onClose = () => windowsStore.close(WINDOW_ID_ARTICLE_TEXT)

  return (
    <WindowContainer>
      <WindowHeader title={title} onBack={onClose}/>
      <Container>
        <Image src={article.imageUrl} alt="" />
        <ContentContainer>
          <Title>{article.title}</Title>
          <Description dangerouslySetInnerHTML={{ __html: descriptionNotNull }} />
        </ContentContainer>
        {/* <BlogArticlesFeedBack date={''} /> */}
      </Container>
    </WindowContainer>
  );
};

const Container = styled.div`
  margin: 0;
  padding: 0;
`;
const ContentContainer = styled.div`
  position: relative;
  padding: 0 16px;
`;
const Title = styled.div`
  color: #000000b2;
  font-size: 24px;
  font-weight: 500;
  margin-top: 32px;
  margin-bottom: 20px;
`;
const Description = styled(FlexContainer)`
  font-size: 18px;
  font-weight: 400;
  color: #000000e5;
  margin-bottom: 32px;
  line-height: 1.4;
`;
const Image = styled.img`
  width: 100%;
  border-radius: 1.5rem;
  margin-top: 0.5rem;
`;
