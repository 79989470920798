import { Notifications } from '@chronos/ui';
import '@chronos/ui/styles/indi.css';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { isMobile } from "react-device-detect";
import styled from 'styled-components';
import { OpenMobileModal } from './components/OpenMobileModal';
import ScheduleModal from './modules/consultations/components/ScheduleModal';
import ServicesModal from './modules/consultations/components/ServicesModal';
import { MainPage } from './modules/main-page/main-page';
import { Popups } from './modules/popups/Popups';
import { checkProduct } from './modules/products/helpers/check-product';
import { Windows } from './modules/windows/Windows';
import { getAppLS, setAppLS } from './store/localStorageHelpers';
import { NotificationsModal } from './modules/notifications/NotificationsModal';
import store from './store/Store';

export default observer(function App() {
  const status = store.events.status;

  useEffect(() => {
    store.init()
    .then(checkProduct)

    const setPwaInstalled = () => {
      store.pwa.addPwaInstallation(store.sessionData?.id)
    }

    store.pwa.addPwaInstallationForIOS(store.sessionData?.id)

    window.addEventListener('appinstalled', setPwaInstalled);

    return () => {
      window.removeEventListener('appinstalled', setPwaInstalled);
    }
  }, []);

  useEffect(() => {
    if(status) document.body.style.pointerEvents = 'initial';

    if (status === 'active') setAppSession()
  }, [status])

  const setAppSession = () => {
    const appLS = getAppLS()
    const newAppLS = {
      ...appLS,
      sessionCount: (appLS?.sessionCount ?? 0) + 1,
      lastSession: dayjs().toISOString(),
    }
    setAppLS(newAppLS)
  }

  return <>
    <MainPage />

    <Windows />
    <Popups />

    <StyledNotifications/>

    {!isMobile && <OpenMobileModal />}

    {store.modal.showHandler.servicesModal && <ServicesModal />}
    {store.modal.showHandler.scheduleModal && <ScheduleModal />}
    {store.modal.showHandler.notificationsModal && <NotificationsModal />}
  </>
})

const StyledNotifications = styled(Notifications)`
  font-size: 0.8rem;
`
