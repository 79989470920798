import dayjs, { Dayjs } from 'dayjs';
import { makeAutoObservable, onBecomeObserved } from 'mobx';
import Api from "../helpers/api";
import { gmtLocal } from '../modules/prognoses/prognoses-daily';
import { DayQuality, IMoonDescription, IMoonInterval, IRecommendation, ITimePeriod, Indi, MoonPhases } from '../libs';

interface IFeedback {
  created: null | string;
  updated: null | string;
  removed: null | string;
  id: number;
  prognosisId: number;
  profit: boolean;
  type: Indi.SetPrognosesFeedback.PrognosisType;
  email: null | string;
  phone: null | string;
}

interface IPrognoses {
  id: number;
  blocks: [
    IPrognosesMonthBlock,
    IPrognosesDayBlock,
    IPrognosesTrendBlock,
    IPrognosesSpecialDayBlock,
    IPrognosesMoonBlock,
  ];
  moon: {
    phase: MoonPhases;
    day: null | number;
  };
  feedbacks?: [IFeedback];
  start: null | string;
  end: null | string;
  created: null | string;
  updated: null | string;
  removed: null | string;
}

interface IPrognosesBlock {
  id: number;
  prognosisId: number;
  created: null | string;
  updated: null | string;
  removed: null | string;
}

interface IPrognosesMonthBlock extends IPrognosesBlock {
  data: IMonthData;
}
interface IPrognosesDayBlock extends IPrognosesBlock {
  data: IDayData;
}
interface IPrognosesTrendBlock extends IPrognosesBlock {
  data: ITrendData;
}
interface IPrognosesSpecialDayBlock extends IPrognosesBlock {
  data: ISpecialDayData;
}
interface IPrognosesMoonBlock extends IPrognosesBlock {
  data: IMoonData;
}

interface IMonthData extends IMonthPrognosis{};
interface IDayData {
  days: IDayPrognosis[];
}
interface ITrendData {
  priority: number;
  trends: ITrendPrognosis[];
}
interface ISpecialDayData {
  days: ISpecialDayPrognosis[];
}
interface IMoonData extends IMoonPrognosis{};

interface IMonthPrognosis {
  id: number;
  prognosisId: number;
  created: null | string;
  updated: null | string;
  removed: null | string;
  description: string;
  recommendations: IRecommendation[];
}
interface IDayPrognosis {
  id: number;
  description: string;
  qualityPct: number;
  qualityType: DayQuality;
  period: ITimePeriod;
  recommendations: IRecommendation[];
  updated: string;
}
interface ITrendPrognosis {
  id: number;
  title: string;
  description: string;
  period: ITimePeriod;
  recommendations: IRecommendation[];
}
interface ISpecialDayPrognosis {
  id: number;
  title: string;
  description: string;
  qualityType: DayQuality;
  period: ITimePeriod;
  recommendations: IRecommendation[];
}
interface IMoonPrognosis {
  id: number;
  prognosisId: number;
  created: null | string;
  updated: null | string;
  removed: null | string;
  priority: number;
  moon: {
    descriptions: IMoonDescription[];
    intervals: IMoonInterval[];
  };
}

export default class Prognoses {
  constructor() {
    makeAutoObservable(this);
    onBecomeObserved(this, 'prognoses', this.getPrognosesFeed.bind(this, dayjs().toISOString(), gmtLocal))
  }

  prognoses: IPrognoses | null = null

  async getPrognosesFeed(date: string, gmt: number) {
    try {
      // получаем прогнозы на текущий и следующий месяц
			const result = await Api.getPrognosesFeed({ date, gmt, to: dayjs(date).add(1, 'month').startOf('month').add(7, 'day').toISOString() });

      // приходит массив прогнозов. Обединяем их blocks в один массив
      result[0].blocks = result.reduce((acc: any[], monthData: Indi.GetPrognosesFeed.Response) => {
        return [...acc, ...monthData.blocks];
      }, [])

	    this.prognoses = result[0];
	    return result;
		
    } catch(e) {
			console.error(`Getting prognoses feed error: ${e}`)
		}
  }

  getDay = (curDay: Dayjs) => {
    if(this.prognoses?.blocks?.length) {
      const [, dayPrognoses] = this.prognoses.blocks;
      const days = dayPrognoses.data.days;
      return days.length
        ? days.find(day => {
            if(day?.period?.start && day?.period?.end) {
              const start = dayjs(day.period.start, 'DD.MM.YYYY HH:mm:ss');
              const end = dayjs(day.period.end, 'DD.MM.YYYY HH:mm:ss');
              return (start.isBefore(curDay) && end.isAfter(curDay)) || start.isSame(curDay);
            }
          })
        : null;
    }
    return null;
  }

  getDayById = (id: number) => {
    if(this.prognoses?.blocks?.length) {
      const [, dayPrognoses] = this.prognoses.blocks;
      const days = dayPrognoses.data.days;
      return days.length
        ? days.find(day => day.id && day.id === id)
        : null;
    }
    return null;
  }

  getMonth = () => {
    if(this.prognoses?.blocks?.length) {
      const [monthPrognoses] = this.prognoses.blocks;
      const month = monthPrognoses.data;
      return month || null;
    }
    return null;
  }

  getActualSpecialDays = (type?: string, excludeId?: number) => {
    if(this.prognoses?.blocks?.length) {
      const [, , , specialDays] = this.prognoses.blocks;
      const { days } = specialDays.data;
      return days.length
        ? days
            .filter(day => {
              if(type && type !== day.qualityType) {
                return false;
              }
              if(excludeId && excludeId === day.id) {
                return false;
              }
              if(day?.period?.start) {
                return dayjs().isBefore(dayjs(day.period.end, 'DD.MM.YYYY HH:mm:ss'));
              }
            })
            .sort((d1, d2) => {
              const _d1 = dayjs(d1.period.start, 'DD.MM.YYYY HH:mm:ss');
              const _d2 = dayjs(d2.period.start, 'DD.MM.YYYY HH:mm:ss');
              if(_d1.isAfter(_d2)) {
                return 1;
              }
              if(_d1.isBefore(_d2)) {
                return -1;
              }
              return 0;
            })
        : null;
    }
    return null;
  }

  getSpecialDayById = (id: number) => {
    if(this.prognoses?.blocks?.length) {
      const [, , , specialDayPrognoses] = this.prognoses.blocks;
      const { days } = specialDayPrognoses.data;
      return days.length
        ? days.find(day => day.id && day.id === id)
        : null;
    }
    return null;
  }

  getTrends = (targetDate?: Dayjs) => {
    const curDate = targetDate || dayjs();
    if(this.prognoses?.blocks?.length) {
      const [, , dayPrognoses] = this.prognoses.blocks;
      const trends = dayPrognoses.data.trends;
      return trends.length
        ? trends.filter(trend => {
            const trendEnd = dayjs(trend.period.end, 'DD.MM.YYYY HH:mm:ss');
            const isFinished = curDate.isAfter(trendEnd);
            if(isFinished) {
              return false;
            }
            return true
          })
        : null;
    }
    return null;
  }

  getTrendById = (trendId: number) => {
    if(this.prognoses?.blocks?.length) {
      const [, , dayPrognoses] = this.prognoses.blocks;
      const trends = dayPrognoses.data.trends;
      return trends.length
        ? trends.find(trend => {
            return trend.id && trend.id === trendId;
          })
        : null;
    }
    return null;
  }

  getOtherTrendsById = (trendId?: number) => {
    if(this.prognoses?.blocks?.length) {
      const [, , dayPrognoses] = this.prognoses.blocks;
      const trends = dayPrognoses.data.trends;
      return trends.length
        ? trends
            .filter(trend => {
              const trendEnd = dayjs(trend.period.end, 'DD.MM.YYYY HH:mm:ss');
              const isFinished = dayjs().isAfter(trendEnd);

              if(isFinished) {
                return false;
              }
              return trendId
                ? trend.id && trend.id !== trendId
                : true;
            })
            .slice(0, 3)
        : null;
    }
    return null;
  }

  getMoonPhase = () => {
    if(this.prognoses?.blocks?.length) {
      const [, , , , moonData] = this.prognoses.blocks;
      const moon = moonData.data.moon;
      return moon || null;
    }
    return null;
  }

  getHaircutIntervals = () => {
    if(this.prognoses?.blocks?.length) {
      const [, , , , moonData] = this.prognoses.blocks;
      const { intervals } = moonData.data.moon;
      return intervals
        .filter(interval => {
          return interval.type === 'HAIRCUT' && (dayjs().isBefore(dayjs(interval.period.start, 'DD.MM.YYYY HH:mm:ss')) || dayjs(interval.period.start, 'DD.MM.YYYY HH:mm:ss').isToday());
        })
        .sort((p1, p2) => {
          const _p1 = dayjs(p1.period.start, 'DD.MM.YYYY HH:mm:ss');
          const _p2 = dayjs(p2.period.start, 'DD.MM.YYYY HH:mm:ss');
          if(_p1.isAfter(_p2)) {
            return 1;
          }
          if(_p1.isBefore(_p2)) {
            return -1;
          }
          return 0;
        });
    }
    return null;
  }

  getIdleIntervals = () => {
    if(this.prognoses?.blocks?.length) {
      const [, , , , moonData] = this.prognoses.blocks;
      const { intervals } = moonData.data.moon;
      return intervals
        .filter(interval => interval.type === 'IDLE')
        .sort((p1, p2) => {
          const _p1 = dayjs(p1.period.start, 'DD.MM.YYYY HH:mm:ss');
          const _p2 = dayjs(p2.period.start, 'DD.MM.YYYY HH:mm:ss');
          if(_p1.isAfter(_p2)) {
            return 1;
          }
          if(_p1.isBefore(_p2)) {
            return -1;
          }
          return 0;
        });
    }
    return null;
  }

  getMoonStatus = () => {
    if(this.prognoses?.moon) {
      const moonStatus = this.prognoses?.moon;
      return moonStatus || null;
    }
    return null;
  }

	async getMoon(date: string, gmt: number) {
		const result = await Api.getMoon({ date, gmt })
		return result
	}

  // getFeedback = (type: Indi.SetPrognosesFeedback.PrognosisType) => {
  //   const feedbacks = this.prognoses?.feedbacks;
  //   if(feedbacks?.length) {
  //     return feedbacks.find(feedback => feedback.type === type);
  //   }
  //   return null;
  // }
}
