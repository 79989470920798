import React from 'react';
import styled, { css } from 'styled-components';

export type TButtonColor =
  'blue' |
  'light-blue' |
  'white' |
  'purpur' |
  'black' |
  'green' |
  'gray' |
  'dark-gray' |
  'orange' |
  'transparent' |
  'gradient' |
  'yellow' |
  'gradient_purpure' |
  'apply' |
  'reset' |
  'emotions' |
  'communication' |
  'travel' |
  'blue-secondary' |
  'green-pear'

const colors: {
  [key in TButtonColor]: {
    background: string,
    color?: string,
    boxShadow?: string
  }
} = {
  blue: {
    background: 'var(--color-gradus-blue)',
  },
  'light-blue': {
		color: 'var(--color-blue)',
    background: 'var(--color-light-blue)',
  },
  'blue-secondary': {
		color: 'var(--color-white)',
    background: 'var(--color-blue-secondary)',
  },
  purpur: {
    background: 'var(--color-purpur)',
  },
  black: {
    background: 'var(--color-black)',
  },
  green: {
    background: 'var(--color-green)',
  },
  'green-pear': {
    color: 'var(--text-primary)',
    background: 'var(--color-green-pear)',
  },
  yellow: {
		color: 'var(--text-primary)',
    background: 'var(--color-yellow)',
  },
  white: {
    background: 'var(--color-white)',
    color: 'var(--text-primary)',
    boxShadow: '0px 3.2px 7.2px rgba(0, 0, 0, 0.13), 0px 0.6px 1.8px rgba(0, 0, 0, 0.11);'
  },
  gray: {
    background: 'var(--color-gray-bg-transparent)',
    color: 'var(--text-primary)'
  },
  'dark-gray': {
    background: 'var(--popup-line-background)',
    color: 'var(--text-primary)'
  },
  orange: {
    background: 'var(--color-orange)',
    color: 'var(--text-primary)'
  },
  transparent: {
    background: 'none',
    color: 'var(--color-white)'
  },
  gradient: {
    background: 'linear-gradient(267.83deg, #DE59F3 25.56%, #4B41B8 58.8%, #1C184A 103.16%)',
    color: 'var(--color-white)'
  },
  gradient_purpure: {
    background: 'linear-gradient(264.22deg, rgba(118, 184, 255, 0.8) 5.77%, rgba(132, 75, 253, 1) 99.67%)',
    color: 'var(--color-white)'
  },
  apply: {
    background: 'var(--button-apply-color-bg)',
    color: 'var(--button-apply-color)'
  },
  reset: {
    background: 'var(--button-reset-color-bg)',
    color: 'var(--button-reset-color)'
  },
  emotions: {
    background: 'var(--sphere-emotions)',
    color: 'var(--color-white)'
  },
  communication: {
    background: 'var(--sphere-communication)',
    color: 'var(--color-white)'
  },
  travel: {
    background: 'var(--sphere-travel)',
    color: 'var(--color-white)'
  },
}

export enum Radius {
  ROUND = '66',
  RECTANGLE = '10'
}


export const Button = ({
  children,
  color = 'black',
  onClick,
  disabled,
  className,
  small,
  radius = Radius.ROUND
}: {
  children: React.ReactNode
  color?: TButtonColor
  onClick: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  disabled?: boolean
  className?: string
  small?: boolean
  radius?: Radius
}) => {
  const c = colors[color];
  const background = c.background;
  const text = c.color || 'var(--color-white)';

  const onClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) return

    onClick(event)
  }

  return <Container
          className={className}
          background={background}
          color={text}
          onClick={onClickHandler}
          boxShadow={c.boxShadow}
          disabled={disabled}
          small={small}
          radius={radius}>
    {children}
  </Container>
}

const Container = styled.div<{ background: string, color: string, boxShadow?: string, disabled?: boolean, small?: boolean, radius: Radius }>`
  width: 100%;
  background: ${p => p.background};
  color: ${p => p.color};
  text-align: center;
  padding: 1rem;
  font-size: 0.875rem;
  border-radius: ${p => `${p.radius}px`};
  cursor: pointer;

  ${p => p.boxShadow && css`
    box-shadow: ${p.boxShadow}
  `}

  ${p => p.disabled && css`
    background: var(--color-gray-bg-transparent);
    color: var(--text-third);
    pointer-events: none;
  `}

  ${p => p.small && css`
    padding: 0.75rem 1rem;
  `}
`
