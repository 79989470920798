import { makeObservable } from "mobx";
import api from "src/helpers/api";
import { getPWALS, setPWALS } from "./localStorageHelpers";
import { isIOS } from "src/helpers/device";

export class Pwa {
  constructor() {
    makeObservable(this)
  }

  installPWAPopupRef: null | HTMLDivElement = null

  isPWA() {
    //@ts-ignore
    return (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true)
  }

  setInstallPWAPopupRef(ref: HTMLDivElement | null) {
    this.installPWAPopupRef = ref
  }

  hideAppInstallPopup() {
    if (this.installPWAPopupRef) {
      this.installPWAPopupRef.style.display = 'none'
    }
  }

  async addPwaInstallation(userId?: number) {
    try {
      const dataPost = {
        date: new Date().toISOString(),
        userAgent: navigator.userAgent,
        userId,
      }

      await api.indiAddPwaInstallation(dataPost)
    } catch (e) {
      console.error(`Error in addPwaInstallation: ${e}`)
    }
  }

  async setUserUsesPwa(userId: number, usesPwa: boolean) {
    try {
      if (usesPwa || !this.isPWA()) return

      const dataPut = {
        userId,
      }

      await api.indiSetUserUsesPwa(dataPut)

      const pwaLS = getPWALS()
      pwaLS.installed = true
      setPWALS(pwaLS)
    } catch (e) {
      console.error(`Error in indiSetUserUsesPwa: ${e}`)
    }
  }

  addPwaInstallationForIOS(userId?: number) {
    const installed = getPWALS()?.installed

    if (installed || !this.isPWA() || !isIOS()) return

    this.addPwaInstallation(userId)
  }
}
