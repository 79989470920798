import React, { useState } from 'react';
import styled from 'styled-components';
import { TagFilled } from '../../assets/icons/system/system-icons';
import { MainPadding, WindowContainer } from '../../components/layout/elements';
import { Button } from '../../components/ui/Button/Button';
import { TextInput } from '../../components/ui/TextInput';
import { windowsStore } from '../../store/Windows';
import { WindowHeader } from '../windows/components/WindowHeader';
import api from '../../helpers/api';
import { PromocodeErrorsType, promocodeErrors } from '../../store/Promocode';
import { show } from '@chronos/ui';
import store from '../../store/Store';

export const WINDOW_ID_PROMOCODE_WINDOW: string = 'WINDOW_ID_PROMOCODE_WINDOW';

interface PromocodeProps {
	subscription?: boolean
}

export const PromocodeWindow = (props: PromocodeProps) => {
  const [ promo, setPromo ] = useState('');
  const [ promoError, setPromoError ] = useState('');
  const onClose = () => windowsStore.close(WINDOW_ID_PROMOCODE_WINDOW);
	const email = store.payments.email || '';

  const checkPromocode = async () => {
    setPromoError('');
    let result;
    try {

			result = await api.checkPromocode(promo, undefined, email)

      // Проверка на корректность приложения
      if (result.actions.app !== 'indi') {
        throw Error('APP_MISMATCH');
      }

      // Проверка на наличие подписки, если она требуется
      if (props.subscription && !result.actions.subscription) {
      throw new Error('SUBSCRIPTION_REQUIRED');
      }

      await api.usePromocode(promo)
			show({
        text: 'Промокод успешно применен',
        type: 'success'
      })

      setTimeout(() => window.location.href = window.location.origin, 1500);

    } catch (e: any) {
      api.sendNotify(`promo error 3: ${e.message}; promo: ${promo}; result: ${result ? JSON.stringify(result) : 'null'}`)
      setPromoError(promocodeErrors[e.message as keyof PromocodeErrorsType])
    }

  }

  return <WindowContainer>
    <WindowHeader title='' onBack={onClose} />
    <Content>
      <TagIcon />
      <Title>Ввод промокода</Title>
      <TextInput
        value={promo}
        onChange={setPromo}
        placeholder={'Введите промокод'}
        style={{
          fontSize: '1.125rem'
        }}
        error={promoError}
      />
    </Content>
    <Footer>
      <Button onClick={async () => await checkPromocode}>Применить промокод</Button>
    </Footer>
  </WindowContainer>
}

const Content = styled(MainPadding)`
  flex: 1;
  padding-top: 5.625rem;
`

const Footer = styled(MainPadding)`
  height: min-content;
`

const TagIcon = styled(TagFilled)`
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: 1rem;
`

const Title = styled.div`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`
