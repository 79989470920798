import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Paywall } from '../../../components/Paywall';
import { gtmSend } from '../../../helpers/url';
import { getArticleWord } from '../../../helpers/words';
import store from '../../../store/Store';
import { windowsStore } from '../../../store/Windows';
import { Article, IBlog, getBlogLs, setBlogLs } from '../helpers/blog';
import { BlogArticle } from './BlogArticle';
import {
  BlogArticleAudio,
  WINDOW_ID_ARTICLE_AUDIO,
} from './BlogArticleAudio';
import {
  BlogArticleText,
  WINDOW_ID_ARTICLE_TEXT,
} from './BlogArticleText';
import {
  BlogArticleVideo,
  WINDOW_ID_ARTICLE_VIDEO,
} from './BlogArticleVideo';


interface BlogGroupProps extends IBlog {
  rollDown: () => void;
}

export const BlogGroup = observer((props: BlogGroupProps) => {
  const [blog, setBlog] = useState(getBlogLs());
  const [showPaywall, setShowPaywall] = useState(false);
  const userId = store.sessionData?.id;
  const articlesNumber = props.articles.length;
  const info = store.sessionData?.indi;
  const status = store.events.status;
  const offerSubscription = Boolean(
    status !== 'active' &&
      info?.trialExpiryDate &&
      dayjs().isAfter(info?.trialExpiryDate)
  );

  const followLink = useCallback(
    (article: Article) => {
      setTimeout(() => window.open(article.link, '_blank'), 500);

      const newBlogLs = getBlogLs();
      newBlogLs.isRead[article.id] = +Date.now();
      setBlogLs(newBlogLs);
      setBlog(newBlogLs);
    },
    [userId, setBlog]
  );

  const blogLs = getBlogLs();

  const getReadDateFromLS = (articleId: number) => {
    const readTime = blogLs.isRead[articleId];
    const currentDate = dayjs()

    if (readTime) {
      const readDate = dayjs(readTime);
      return readDate.isSame(currentDate, 'day') ? 0 : readTime;
    }

    return 0;
  };

  let sortedArticles = props.articles;

  if (props.rotation) {
    sortedArticles = sortedArticles.slice().sort((a, b) => {
      const aReadDate = getReadDateFromLS(a.id);
      const bReadDate = getReadDateFromLS(b.id);
      return aReadDate - bReadDate;
    });
  }

  const handleArticleClick = (articleId: number) => {
    const newBlogLs = getBlogLs();
    newBlogLs.isRead[articleId] = +Date.now();
    setBlogLs(newBlogLs);
    setBlog({ ...newBlogLs });
  };

  const onclickHandler = useCallback(
    (article: Article) => {
      props.rollDown();

      if (status === 'active' || !article.subscription) {
        switch (article.typeFormat) {
          case 'link':
            followLink(article);
            break;
          case 'text':
            windowsStore.open(
              WINDOW_ID_ARTICLE_TEXT,
              <BlogArticleText article={article} title={props.title} />,
            );
            break;
          case 'audio':
            windowsStore.open(
              WINDOW_ID_ARTICLE_AUDIO,
              <BlogArticleAudio article={article} title={props.title}/>,
            );
            break;
          case 'video':
            windowsStore.open(
              WINDOW_ID_ARTICLE_VIDEO,
              <BlogArticleVideo article={article} title={props.title}/>,
            );
            break;
        }
        gtmSend({ event: article.event, user_id: userId ? userId : '' });
        handleArticleClick(article.id);
      } else {
        setShowPaywall(true);
      }
    },
    [followLink, setShowPaywall, status, props, userId]
  );

  const getNewCount = () => {
    const newArticles = props.articles.filter(
      (article) => !Object.keys(blog.isRead).includes(String(article.id))
    );
    return newArticles.length;
  };

  return (
    <Container isMobile={isMobile}>
      <Title>{props.title}</Title>

      <Subtitle>
        <Count>
          {articlesNumber} {getArticleWord(articlesNumber)}
        </Count>
        {getNewCount() > 0 && (
          <NewCount>
            {getNewCount()} {getNewCount() === 1 ? 'новая' : 'новых'}
          </NewCount>
        )}
      </Subtitle>

      <ContentContainer>
        <Content>
          {sortedArticles.map((article, index) => (
            <BlogArticle
              key={`blog-article-${index}`}
              article={article}
              onClick={() => onclickHandler(article)}
              isNew={!blog.isRead[article.id]}
              isToday={props.rotation && index === 0}
              lock={status !== 'active'}
            />
          ))}
        </Content>
      </ContentContainer>

      {showPaywall &&
        createPortal(
          <Paywall
            offerSubscription={offerSubscription}
            onClose={() => setShowPaywall(false)}
          />,
          document.body
        )}
    </Container>
  );
});

const Container = styled.div<{ isMobile: boolean }>`
  margin-bottom: 1.25rem;
  overflow-x: hidden;
`;

const Title = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
`;

const Subtitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Count = styled.div`
  color: var(--text-third);
  margin-top: 0.5rem;
`;

const NewCount = styled.div`
  color: var(--color-gradus-blue);
  margin-top: 0.5rem;
`;

const ContentContainer = styled.div`
  margin-top: 0.5rem;
  overflow-x: scroll;
`;

const Content = styled.div`
  display: inline-flex;
  justify-content: space-between;

  margin-bottom: 0.5rem;
`;
