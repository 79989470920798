import styled from 'styled-components'
import { getArticleWord } from '../../../helpers/words'
import { getBlogLs } from '../helpers/blog'
import { useEffect, useState } from 'react'
import store from 'src/store/Store'
import { observer } from 'mobx-react-lite'

interface BlogNewProps {
  newCounterRef?: React.RefObject<HTMLDivElement>
}

export const BlogNew = observer((props: BlogNewProps) => {
  const blogs = store.products.blogs
  
  useEffect(() => {
    store.products.getArticlesGroups()
  }, []);
  
  const blog = getBlogLs();

  const count = blogs.reduce((sum, group) => {
		const filteredArticles = group.articles.filter(article => !blog.isRead[article.id])
		return sum + filteredArticles.length
	}, 0)

  if (count === 0) return null;

  return (
    <Container ref={props.newCounterRef}>
      {count} новых {getArticleWord(count)}
    </Container>
  )
})

const Container = styled.div`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--color-white);
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  background: var(--color-black);
  transition: opacity 0.2s;
`
