import { observer } from 'mobx-react-lite';
import { MainPadding } from '../../components/layout/elements';
import { BlogGroup } from './components/BlogGroup';
import { useEffect } from 'react';
import store from 'src/store/Store';

interface BlogProps {
  rollDown: () => void
}

export const Blog = observer((props: BlogProps) => {
  const blogs = store.products.blogs

  useEffect(() => {
    store.products.getArticlesGroups()
  }, []);

  return (
    <MainPadding className="no-print">
      {blogs.map((group, index) => (
        <BlogGroup
          key={`blog-group-${index}`}
          title={group.title}
          articles={group.articles}
          rotation={group.rotation}
          rollDown={props.rollDown}
        />
      ))}
    </MainPadding>
  );
});
